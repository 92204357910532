export const calCulatePercent = (soldYesterday, soldToday) => {
    if (parseInt(soldYesterday) !== parseInt(soldToday)) {
        if (soldYesterday === 0) {
            const sum = (((parseInt(soldToday) - parseInt(soldYesterday)) / parseInt(soldToday)) * 100);
            return sum.toFixed(2)
        } else {
            const sum = (((parseInt(soldToday) - parseInt(soldYesterday)) / parseInt(soldYesterday)) * 100);
            return sum.toFixed(2)
        }

    } else {
        return null
    }
}

export const CalcSoldToday = (shop) => {
    let sum = 0
    shop.channel.map((record) => (
        sum = (sum + parseInt(record.soldToday))
    ))
    return sum
}

export const CalcSoldYesterday = (shop) => {
    let sum = 0
    shop.channel.map((record) => (
        sum = (sum + parseInt(record.soldYesterday))
    ))
    return sum
}

//##FetchOne data only Today
export const getHoursDataToday = (graphs) => {
    // let today = new Date().toLocaleDateString()
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    // console.log(today)

    let datas = graphs

    if (datas) {
        let fetchOne = datas.find((obj) => obj.date >= today)
        if (fetchOne) {
            let getFetchHours = fetchOne.hours
            // console.log(getFetchHours)
            return getFetchHours
        } else {
            return []
        }
    }
}

//##FetchOne data only Yesterday
export const getHoursDataYesterday = (graphs) => {
    // let today = new Date().toLocaleDateString()
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    // console.log(today)
    let datas = graphs

    if (datas) {
        let fetchOne = datas.find((obj) => obj.date < today)
        if (fetchOne) {
            let getFetchHours = fetchOne.hours
            // console.log(getFetchHours)
            return getFetchHours
        } else {
            return []
        }
    }
}


//## TOTAL sold Today
export const grandTotalSoldToday = (shops) => {
    let datas = shops
    let sum = 0
    if (datas.length > 0) {
        datas.map((data) => (
            data.channel.map((record) => (
                sum = (sum + parseInt(record.soldToday))
            )
            )))
        return sum
    } else {
        return
    }
}

//##TOTAL sold yesterday
export const grandTotalSoldYesterday = (shops) => {
    let datas = shops
    let sum = 0
    if (datas.length > 0) {
        datas.map((data) => (
            data.channel.map((record) => (
                sum = (sum + parseInt(record.soldYesterday))
            )
            )))
        return sum
    } else {
        return
    }
}

//##FIND DATA TOTALSOLD THISWEEK
export const findSoldThisWeek = (shops) => {
    let datas = shops
    let sum = 0
    if (datas.length > 0) {
        const findData = datas.find((item) => item)
        sum = parseInt(findData.total)
        return sum
    } else {
        return
    }
}

//##FIND DATA TOTALSOLD YESTERDAY
export const findSoldYesterday = (shops) => {
    let datas = shops
    let sum = 0
    if (datas.length > 0) {
        const findData = datas.find((item) => item)
        sum = parseInt(findData.total)
        return sum
    } else {
        return
    }
}