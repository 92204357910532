import { Link } from "react-router-dom";
//import { Tv } from 'react-bootstrap-icons';
import app from "../firebase/base";
import { ReactComponent as Logo } from "../logo.svg";
import { MDBIcon } from 'mdb-react-ui-kit';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg p-4">
            <div className="container-fluid">
                <div className="brand">
                    <Logo className="brand-logo" />
                    <Link className="nav-title ps-3 text-white fs-4" style={{ position: 'relative', left: '-5px' }} to="/">
                        <span>Business Insight</span>
                    </Link>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* <li className="text-info nav-item">
                        <Tv size="24" className="me-2"/> Live Monitor
                    </li> */}
                        {/* <li className="nav-item">
                        <Link className="nav-link active" to="/">ภารรวมทุกช่องทาง</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="#">การเข้าชม</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="#">สินค้า</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="#">ยอดขาย</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="#">ลูกค้า</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/signup">ลงทะเบียน</Link>
                    </li> */}
                    </ul>
                    {/* <div className="d-flex pe-3">
                        <span className="nav-live">Live Monitor</span>
                    </div> */}
                    <div className="nav-logout">
                        <Link className="ps-2 text-white fw-bold" to="#" onClick={() => app.auth().signOut()}>
                            <MDBIcon className='text-white' icon='sign-out-alt' size='1x' />
                            <span className="ps-3">ออกจากระบบ</span>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;