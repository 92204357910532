import { ReactComponent as IconDown } from '../../icons/icon-trenddown.svg'

const downCellRenderer = (props) => {
    const diff = props.value
    return (
        <>
            <div className='d-inline rounded-3 bg-danger p-2'>
                {diff}<IconDown className='ms-1' />
            </div>
        </>
    );
}

export default downCellRenderer;