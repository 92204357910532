import { MDBSpinner } from 'mdb-react-ui-kit'
import useFetch from "../useFetch";
import { CountUp } from 'use-count-up'
import { findSoldYesterday } from './Calcu'
import { ReactComponent as IconYesterday } from './icons/icon-yesterday.svg'

const TotalThisWeek = () => {

    const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL + '/api/v1/soldyesterday/get' : process.env.REACT_APP_API_URL + '/soldthisyesterday'

    const { error, isPending, data: thisYesterday } = useFetch(url, process.env.NODE_ENV)

    return (
        <>
            <div className='align-self-center flex-fill'>
                {error && <h2 className="text-center pt-5">{error}</h2>}
                {isPending &&
                    <div className='d-flex justify-content-center'>
                        <MDBSpinner className='m-3 mt-5' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                    </div>}

                {thisYesterday &&
                    <div className="borderRound20 p-4 bg-dark shadow-1-strong">
                        <div className="row">
                            <div className="col-md-3 d-flex">
                                <div className='flex-grow-1 bg-white borderRound15 d-flex justify-content-center align-items-center me-3'>
                                    <IconYesterday />
                                </div>

                            </div>
                            <div className="col-md-9 text-md-start">
                                <h3 className="fs-5 ps-2 text-white-50 bg-dark">ยอดขายเมื่อวาน</h3>
                                <h3 className="fs-5 ps-2 text-white">
                                    <span>&#3647;</span><CountUp thousandsSeparator="," isCounting end={findSoldYesterday(thisYesterday)} key={findSoldYesterday(thisYesterday)} />
                                </h3>
                            </div>
                        </div>


                    </div>
                }
            </div>
        </>
    );
}

export default TotalThisWeek;