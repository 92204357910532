import React, { useState, useEffect } from 'react'
import app from './base'

import { MDBSpinner } from 'mdb-react-ui-kit';

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        app.auth().onAuthStateChanged( (user) => {
            setCurrentUser(user)
            setPending(false)
        })
    }, []);

    if(pending){
        return <div className="text-center">
            <MDBSpinner className='m-3 mt-5' role='status'>
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        </div>
    }

    return (
        <AuthContext.Provider value={{
            currentUser,
        }}
        >
            {children}
        </AuthContext.Provider>
    )
}