import TotalSold from "./TotalSold"

const Totals = () => {

    return ( 
        <div className="container-fluid container-totals">
            <TotalSold/>
        </div>
     );
}
 
export default Totals;