import Chart from "./monitor/Chart";
import Monitor from "./monitor/Monitor";
import Navbar from "./Navbar";
import Totals from "./Totals";
import { Helmet } from 'react-helmet-async'

const Dashboard = () => {
    return (
        <div>
            <Helmet>
                <style>{"body { background-color: black; }"}</style>
            </Helmet>
            <Navbar />
            <div className="container-fluid">
                <div className="row">
                    <Totals />
                    <div className="col-md-5 pt-3">
                        <div className="gradient-border-mask p-4 pt-0 pb-3">
                            <Monitor />
                        </div>
                    </div>
                    <div className="col-md-7 pt-3">
                        <div className="gradient-border-mask p-4">
                            <Chart />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;