import {useState} from 'react'
import Navbar from "../../Navbar"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import axios from "axios"
import * as yup from 'yup';
import Moment from 'moment';
// import Showdata from './Showdata';
import { generateAuthSignature } from '../../Authen'
import PaginateData from "./PaginateData";
import Download from "./Download";

const PromotionReport = () => {

    const options = [
        { value: 'flashsale', label: 'Flash sale' },
        { value: 'promotion', label: 'Promotion' },
    ];

    const [selectedType, setSelectedType] = useState(null);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [results, setResults] = useState(null)
    const [menus, setMenus] = useState([])
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [selected, setSelected] = useState()
    const [selectedName, setSelectedName] = useState()
    const [datas, setDatas] = useState([])

    // const schema = yup.object({
    //     birth_date: Yup.string().required("profile.validation.birth_date.required")
    // });

    const schema = yup.object().shape({
        start: yup.string().required("โปรดระบุ วันที่เริ่ม"),
        end: yup.string().required("โปรดระบุ วันที่จบ")
     });

    const handleType = (opt) => {
        setSelectedType(opt)
    }

    const timeStamp = new Date().getTime()
    const onSubmit = (values, {resetForm}) => {
        setSelected(null)
        setDatas([])
        setLoading(true);
        //console.log(values)
        const dataType = values.type
        const dataStartDate = values.start
        const dataEndDate = values.end
        //console.log(dataType,dataStartDate,dataEndDate)

        const objData = {
            // type: dataType,
            // start_date: dataStartDate,
            // end_date: dataEndDate
            "time": timeStamp,
            "type": dataType,
            "start_date": dataStartDate,
            "end_date": dataEndDate
        }

        const urlApi = 'https://biapi.topvalue.com/api/v1/promotions/get'
        const privateKey = process.env.REACT_APP_API_PRIVATEKEY

        if(values){
            axios.request({
                // method: 'get',
                // url: 'http://localhost:5000/promotions',
                // params: objData,
                method: 'post',
                url: urlApi,
                data: JSON.stringify(objData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": generateAuthSignature(urlApi, objData, privateKey)
                }
            }).then(response => {
                //console.log(response.data)
                setResults(response.data)
                makeMenuItems(response.data)
                setLoading(false);
            }).catch( (err) => {
                console.log('errorResponse>>',err.response)
                setLoading(false)
                setIsError(true)
            })
        }else{
            console.log('Error There is no data.')
            setIsError(true)
        }

        // resetForm()
        // setSelectedType(null)
        // setStartDate(null)
        // setEndDate(null)
    }


    // console.log('start>>',new Date(startDate))
    // console.log('end>>',endDate)
    // console.log('results>>',results)


    const makeMenuItems = (datas) => {
        const dataMenus = datas.map( data => {
            return {
                id: data.id,
                name: data.promotion_name
            }
        })
        setMenus(dataMenus)
    }
    // console.log('menu>>',menus)


    const handleOnSelect = (opt) => {
        setSelected(opt)
        getDataBySelectedId(opt.id)
    }

    const getDataBySelectedId = (ReqId) => {
        const findData = results.find( (result) => result.id === ReqId)  
        const getDataName = findData.promotion_name
        const getItems = findData.items
        setDatas(getItems)
        setSelectedName(getDataName)
    }

    return ( 
        <div>
            <Navbar />
            <div className="container">
                <Formik
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    // validateOnChange={false}
                    validateOnChange={true}
                    initialValues={{
                        type: '',
                        start: '',
                        end: '',
                    }}
                    >
                    {({
                        values,
                        touched,
                        dirty,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        setFieldValue,
                        setFieldTouched,
                        resetForm
                    }) => {
                        return (
                            <Form className="row g-3 mb-5 mt-5" noValidate onSubmit={handleSubmit}>
                                <label className="col-1 col-form-label text-center">รูปแบบโปรโมชั่น</label>
                                <div className="col-3">
                                    <Select
                                        name="type"
                                        id="type"
                                        value={selectedType}
                                        //defaultValue={selectedType}
                                        onChange={ selectedOpt => {
                                            handleType(selectedOpt)
                                            handleChange("type")(selectedOpt.value)
                                        }}
                                        options={options}
                                    />
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <label className="col-3 col-form-label text-end">วันที่เริ่ม</label>
                                        <div className="col-8">
                                            <DatePicker 
                                                className="form-control"
                                                name="start" 
                                                id="start" 
                                                autoComplete="off"
                                                selected={startDate} 
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => {
                                                    setStartDate(date)
                                                    setFieldValue("start", Moment(date).format('DD-MM-YYYY'))
                                                    //handleChange("start")(date)
                                                }} />
                                                <span className="error">{errors.start}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <label className="col-3 col-form-label text-end">วันที่จบ</label>
                                        <div className="col-8">
                                            <DatePicker 
                                                className="form-control"
                                                name="end" 
                                                id="end" 
                                                autoComplete="off"
                                                selected={endDate} 
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => {
                                                    setEndDate(date)
                                                    setFieldValue("end", Moment(date).format('DD-MM-YYYY'))
                                                    //handleChange("end")(date)
                                                }} />
                                            <span className="error">{errors.end}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <button type="submit" className="btn btn-dark">
                                        <span>ค้นหา</span>
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                { loading}

                {isError && 
                    <div className="text-center">
                        <h4 className="text-center pt-3 text-danger">Something went wrong. Please try again later.</h4>
                    </div>}
                
                {loading && 
                    <div className="col-12 text-center">
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }

                {/* {results && 
                    (results.length === 0) ? 'No data' : 
                    <Showdata results={results} menus={menus}/>} */}



                {results &&
                    <div className="results-table-data">
                        {(results.length === 0 || results === null) ? '' :
                            <div>
                            <div className="row g-3">
                                <label className="col-1 col-form-label text-center">ชื่อโปรโมชั่น</label>
                                <div className="col-7">
                                    <Select
                                            name="type"
                                            id="type"
                                            value={selected}
                                            //defaultValue={selectedType}
                                            onChange={ selectedOpt => {
                                                handleOnSelect(selectedOpt)
                                            }}
                                            options={menus}
                                            getOptionLabel={x => x.name}
                                            getOptionValue={x => x.id}
                                        />
                                </div>
                                <div className="col-4 text-center">
                                    {/* <button type="button" className="btn btn-light">Download</button> */}
                                    {datas &&
                                        <Download datas={datas} selectedName={selectedName}/>}
                                </div>
                            </div>
                            <div className="show-data mt-4">
                                {datas &&
                                    <PaginateData datas={datas} />}
                            </div>
                        </div>}
                    </div>
                }
            
            
            


                    
            </div>
        </div>
     );
}
 
export default PromotionReport;