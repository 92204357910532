import { useState, useEffect } from 'react'
import axios from 'axios';
import CryptoJS from 'crypto-js';

const useFetch = (url, mode) => {

    // console.log(url)
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    const generateAuthSignature = (Url, body, pvKey) => {
        const requestBody = JSON.stringify(body)
        const unHash = Url + '|' + requestBody

        const enCrytpData = CryptoJS.HmacSHA256(unHash, pvKey).toString(CryptoJS.enc.hex);
        return enCrytpData
    }


    // console.log(generateAuthSignature(url, objData, privateKey))
    // console.log(JSON.stringify(objData))

    useEffect(() => {
        //Render
        const objData = { "time": 1234567 }
        const privateKey = process.env.REACT_APP_API_PRIVATEKEY

        let repeat;
        const fetchApi = async () => {
            try {
                if (mode === 'production') { // PRODUCTION
                    await axios.request({
                        method: 'post',
                        url: url,
                        // data: '{"time":1234567}',
                        data: JSON.stringify(objData),
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": generateAuthSignature(url, objData, privateKey)
                        }

                    }).then(res => {
                        setIsPending(false);
                        setData(res.data);
                        setError(null);
                    })
                        .catch(err => {
                            setError(err.message);
                        });
                } else { // DEVELOPMENT
                    await axios.get(url)
                        .then(res => {
                            setIsPending(false);
                            setData(res.data);
                            setError(null);
                        })
                        .catch(err => {
                            setError(err.message);
                        });
                }

                repeat = setTimeout(fetchApi, 60000); // request again after a minute

            } catch (err) {
                setIsPending(false);
                setError(err.message);
            }
        };

        fetchApi()

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }

    }, [mode, url])

    return { data, isPending, error };
}

export default useFetch;