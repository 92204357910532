import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Channels from "./Channels";
import { calCulatePercent, CalcSoldToday, CalcSoldYesterday } from '../../Calcu'
import Icon from '../../icons/Icon'
import { CountUp } from 'use-count-up'
import { ReactComponent as IconUp } from '../../icons/icon-trendup.svg'
import { ReactComponent as IconDown } from '../../icons/icon-trenddown.svg'

const ShopList = ({ shops }) => {

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "20px",
        slidesToShow: 3,
        speed: 1000,
        autoplaySpeed: 9000,
        autoplay: true,
        focusOnSelect: true,
        arrows: false
    };


    return (
        <>
            <div className="slider-shops">
                <p className="text-white pt-3">ช่องทางการขาย</p>
                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...settings}>
                    {shops.map((shop) => {
                        return (
                            (
                                <div key={shop.id}>
                                    <div className="shop-icon text-center">
                                        <span><Icon name={shop.shopName} /></span>
                                        <span>{shop.shopName}</span>
                                    </div>
                                </div>

                            )
                        )
                    })}
                </Slider>
            </div>

            <div className="slider-shop-details">
                <Slider asNavFor={nav1} ref={(slider2) => setNav2(slider2)} arrows={false}>
                    {shops.map((shop, i) => (
                        <div key={i}>
                            <div className="shop-todaysold text-center p-2 text-white mb-3 bg-dark shadow-1-strong borderRound15">
                                <span>รวมยอดขายวันนี้ </span>
                                <span className="fs-5 p-2 align-middle">
                                    <span>&#3647;</span>
                                    <CountUp thousandsSeparator="," isCounting end={CalcSoldToday(shop)} key={CalcSoldToday(shop)} />
                                </span>

                                <span className={`sh-percentage p-2 align-middle rounded-3 ${calCulatePercent(CalcSoldYesterday(shop), CalcSoldToday(shop)) > 0 ? 'bg-success' : 'bg-danger'} `}>

                                    {calCulatePercent(CalcSoldYesterday(shop), CalcSoldToday(shop))}
                                    {calCulatePercent(CalcSoldYesterday(shop), CalcSoldToday(shop)) !== null ? '%' : ''}

                                    {calCulatePercent(CalcSoldYesterday(shop), CalcSoldToday(shop)) > 0
                                        ? <IconUp className="ms-2" />
                                        : <IconDown className="ms-2" />}
                                </span>


                            </div>
                            <Channels key={i} shopId={shop.id} channelsData={shop.channel} />
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
}

export default ShopList;