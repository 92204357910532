import useFetch from '../useFetch'
import { calCulatePercent, grandTotalSoldYesterday, grandTotalSoldToday } from './Calcu'
import { MDBSpinner } from 'mdb-react-ui-kit'
import { ReactComponent as IconUp } from './icons/icon-trendup.svg'
import { ReactComponent as IconDown } from './icons/icon-trenddown.svg'


const TotalDiff = () => {

    const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL + '/api/v1/shops/get' : process.env.REACT_APP_API_URL + '/shops'

    const { error, isPending, data: shops } = useFetch(url, process.env.NODE_ENV)

    let totalSoldYerterday = 0
    let totalSoldToday = 0
    let diffPercentage = 0
    if (shops) {
        totalSoldYerterday = grandTotalSoldYesterday(shops)
        totalSoldToday = grandTotalSoldToday(shops)
        diffPercentage = calCulatePercent(totalSoldYerterday, totalSoldToday)
    }

    return (
        <span className={`rounded-3 p-2 pt-1 pb-1 ${diffPercentage > 0 ? 'bg-success' : 'bg-danger'}`}>
            {error && <h2 className="text-center pt-5">{error}</h2>}
            {isPending &&
                <div className='d-flex justify-content-center'>
                    <MDBSpinner className='m-3 mt-5' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>}

            {diffPercentage > 0 ? '+' + diffPercentage : diffPercentage}
            {diffPercentage !== null ? '%' : ''}

            {diffPercentage > 0
                ? <IconUp className='ms-2' />
                : <IconDown className='ms-2' />}

        </span>
    );
}

export default TotalDiff;