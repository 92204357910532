import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Signup from './components/Signup';
import { AuthProvider } from "./firebase/Auth";
import { PrivateRoute } from "./firebase/PrivateRoute";
import PromotionReport from './components/containers/promotion/PromotionReport';


const App = () => {

  return (
    <AuthProvider>
      <Router>
        <div>
          <PrivateRoute exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/signup" component={Signup} />
          <PrivateRoute exact path="/promotion" component={PromotionReport} />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
