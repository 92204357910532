import IconRank1 from '../../icons/rank1.png'
import IconRank2 from '../../icons/rank2.png'
import IconRank3 from '../../icons/rank3.png'

const getRanks = (rankVal) => {
    if (rankVal === 1) {
        return <img src={IconRank1} alt="Logo" />
    } else if (rankVal === 2) {
        return <img src={IconRank2} alt="Logo" />
    } else if (rankVal === 3) {
        return <img src={IconRank3} alt="Logo" />
    } else {
        return ''
    }

}

const rankCellRenderer = (rank) => {
    return (
        <>
            <div>
                {rank.value > 3 && <span className='ps-2'>{rank.value}</span>}
                <span className='rankImg'>{getRanks(rank.value)}</span>
            </div>
        </>
    );
}

export default rankCellRenderer;