import { useState } from 'react';
// import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AgGridReact } from 'ag-grid-react';


import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import { calCulatePercent } from '../../Calcu'
import upCellRenderer from './upCellRenderer'
import downCellRenderer from './downCellRenderer'
import rankCellRenderer from './rankCellRenderer';


const Channels = ({ shopId, channelsData }) => {

    //Sorting DESC soldToday
    channelsData.sort((a, b) => (a.soldToday < b.soldToday ? 1 : -1))

    const calcPerchannel = (sToday, sYesterday) => {
        return calCulatePercent(parseInt(sToday), parseInt(sYesterday))
    }
    const InitialRowData = channelsData.map((obj, index) => {
        index++
        return {
            id: index,
            channelTitle: obj.channelName,
            soldToday: obj.soldToday,
            soldYesTerday: obj.soldYesterday,
            diff: calcPerchannel(obj.soldToday, obj.soldYesterday)
        }
    })

    const formatNumber = (number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    const currencyFormatter = (params) => {
        return '฿' + formatNumber(params.value);
    }
    const diffFormatter = (params) => {
        if (parseInt(params.value) > 0) {
            return '+' + params.value + '%';
        } else if (parseInt(params.value) < 0) {
            return params.value + '%';
        } else {
            return params.value;
        }

    }


    const [rowData] = useState(InitialRowData)
    const [styleGrid] = useState({
        height: 500,
        width: '100%',
    });

    const [columnDefs] = useState([
        {
            field: 'id', headerName: 'อันดับ', initialWidth: 126,
            cellRendererSelector: (params) => {
                return {
                    component: rankCellRenderer
                }
            }
        },
        { field: 'channelTitle', headerName: 'ร้านค้า' },
        { field: 'soldToday', headerName: 'ยอดขาย', valueFormatter: currencyFormatter },
        {
            field: 'diff', headerName: 'การเปลี่ยนแปลง', valueFormatter: diffFormatter,
            cellRendererSelector: (params) => {
                const upperDetails = {
                    component: upCellRenderer,
                };

                const downDetails = {
                    component: downCellRenderer,
                };

                if (params.value > 0) return upperDetails;
                else if (params.value < 0) return downDetails;
                else return undefined;
            },
        }
    ])

    const gridOptions = {
        // rowClassRules: {
        //     "row-minus": params => params.api.getValue("diff", params.node) < 0,
        //     "row-plus": params => params.api.getValue("diff", params.node) >= 1
        // }
    }


    return (
        <div className="ag-theme-alpine-dark" style={styleGrid}>
            <AgGridReact
                rowData={rowData}
                defaultColDef={{ sortable: true, filter: false }}
                columnDefs={columnDefs}
                gridOptions={gridOptions}>

                {/* <AgGridColumn field="id" headerName="อันดับ" maxWidth="120"></AgGridColumn>
                <AgGridColumn field="channelTitle" headerName="ร้านค้า"></AgGridColumn>
                <AgGridColumn field="soldToday" headerName="ยอดขาย" valueFormatter={currencyFormatter}></AgGridColumn>
                <AgGridColumn field="diff" headerName="การเปลี่ยนแปลง"></AgGridColumn> */}

            </AgGridReact>
        </div>
    );
}

export default Channels;