import React, { useCallback } from "react";
import { withRouter } from "react-router";
import app from "../firebase/base";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Navbar from "./Navbar";


const Signup = ({ history }) => {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .required('Email is required')
          .email('Email is invalid'),
        password: Yup.string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters')
          .max(40, 'Password must not exceed 40 characters'),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = useCallback(
        async (data) => {
            // event.preventDefault()
            // const { email, password } = event.target.elements
            try{
                await app.auth().createUserWithEmailAndPassword(data.email,data.password)
                history.push("/")
            }catch (error){
                alert(error)
            }
        },
        [history]
    )

    // const handleSignUp = useCallback(
    //     async (event) => {
    //         event.preventDefault()
    //         const { email, password } = event.target.elements
    //         try{
    //             await app.auth()
    //             .createUserWithEmailAndPassword(email.value,password.value)

    //             history.push("/")
    //         } catch (error) {
    //             alert(error)
    //         }
    //     },
    //     [history]
    // )



    return ( 
        <>
            <Navbar />
            <div className="container">
                <div className="col-md-5 offset-md-4 p-3 mt-3 border border-2 rounded login-form">
                    <h2 className="text-center">Sign up</h2>
                    <form className="row g-3 needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        
                        <div className="col-12">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input name="email" id="email" type="text"
                                {...register('email')}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>


                        <div className="col-12">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                                name="password" id="password" type="password"
                                {...register('password')}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>


                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Sign Up</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
        
    );
}
 
export default withRouter(Signup);