import ShopList from './ShopList';

const Shops = ({shops}) => {
    // console.log(shops)
    return ( 
        <div>
            <ShopList shops={shops}/>
        </div>
     );
}
 
export default Shops;