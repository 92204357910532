import Shops from "../containers/shop/Shops"
import useFetch from "../../useFetch";
import { MDBSpinner } from 'mdb-react-ui-kit';

const Monitor = () => {

    const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL + '/api/v1/shops/get' : process.env.REACT_APP_API_URL + '/shops'

    const { error, isPending, data: shops } = useFetch(url, process.env.NODE_ENV)

    return (
        <div>
            {error && <h2 className="text-center pt-5">{error}</h2>}
            {isPending &&
                <div className='d-flex justify-content-center'>
                    <MDBSpinner color='light' className='m-5' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>}
            {shops && <Shops shops={shops} />}
        </div>
    );
}

export default Monitor;