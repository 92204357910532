import { useState, useEffect } from 'react'
import axios from 'axios';
import CryptoJS from 'crypto-js';

const generateAuthSignature = (Url, body, pvKey) => {
    const requestBody = JSON.stringify(body)
    const unHash = Url + '|' + requestBody

    const enCrytpData = CryptoJS.HmacSHA256(unHash, pvKey).toString(CryptoJS.enc.hex);
    return enCrytpData
}

const useFetchApexChart = (url, mode) => {

    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    const [todaysold, setTodaysold] = useState([])
    const [yesterdaysold, setYesterdaysold] = useState([])



    useEffect(() => {
        const objData = { "time": 1234567 }
        const privateKey = process.env.REACT_APP_API_PRIVATEKEY

        let repeat;
        const fetchApi = async () => {
            try {
                if (mode === 'production') {
                    axios.request({
                        method: 'post',
                        url: url,
                        data: JSON.stringify(objData),
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": generateAuthSignature(url, objData, privateKey)
                        }

                    }).then(res => {
                        setIsPending(false);
                        const todayDate = new Date(new Date().toLocaleDateString())
                        const resData = res.data

                        //Today Totalsold
                        const FindTodayData = resData.find((obj) => new Date(obj.date) >= todayDate)
                        const getDataToday = FindTodayData.hours.map(obj => {
                            // console.log(obj)
                            return (
                                {
                                    x: obj.time,
                                    y: parseInt(obj.totalSold)
                                }
                            )
                        })
                        setTodaysold(getDataToday)
                        // console.log(todaysold)


                        //Yesterday Totalsold
                        const FindYesterDayData = resData.find((obj) => new Date(obj.date) < todayDate)
                        const getDataYesterday = FindYesterDayData.hours.map(obj => {
                            // console.log(obj)
                            return (
                                {
                                    x: obj.time,
                                    y: parseInt(obj.totalSold)
                                }
                            )
                        })
                        setYesterdaysold(getDataYesterday)
                        // console.log(yesterdaysold)


                    }).catch(err => {
                        setError(err.message);
                    });
                } else {
                    await axios.get(url)
                        .then(res => {
                            setIsPending(false);
                            const todayDate = new Date(new Date().toLocaleDateString())
                            const resData = res.data

                            //Today Totalsold
                            const FindTodayData = resData.find((obj) => new Date(obj.date) >= todayDate)
                            const getDataToday = FindTodayData.hours.map(obj => {
                                // console.log(obj)
                                return (
                                    {
                                        x: obj.time,
                                        y: parseInt(obj.totalSold)
                                    }
                                )
                            })
                            setTodaysold(getDataToday)
                            // console.log(todaysold)


                            //Yesterday Totalsold
                            const FindYesterDayData = resData.find((obj) => new Date(obj.date) < todayDate)
                            const getDataYesterday = FindYesterDayData.hours.map(obj => {
                                // console.log(obj)
                                return (
                                    {
                                        x: obj.time,
                                        y: parseInt(obj.totalSold)
                                    }
                                )
                            })
                            setYesterdaysold(getDataYesterday)
                            // console.log(yesterdaysold)


                        }).catch(err => {
                            setError(err.message);
                        });
                }
                repeat = setTimeout(fetchApi, 60000); // request again after a minute

            } catch (err) {
                setIsPending(false);
                setError(err.message);
            }
        }
        fetchApi()

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }

    }, [mode, url])
    return { error, isPending, todaysold, yesterdaysold };
}

export default useFetchApexChart;