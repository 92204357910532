import useFetch from '../useFetch'
import { grandTotalSoldToday } from './Calcu'
import { MDBSpinner } from 'mdb-react-ui-kit';
import { CountUp } from 'use-count-up'

const TotalToday = () => {

    const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL + '/api/v1/shops/get' : process.env.REACT_APP_API_URL + '/shops'

    const { error, isPending, data: shops } = useFetch(url, process.env.NODE_ENV)
    // console.log(shops)

    return (
        <>
            {error && <h2 className="text-center pt-5">{error}</h2>}
            {isPending &&
                <div className='d-flex justify-content-center'>
                    <MDBSpinner className='m-3 mt-5 text-center' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>}
            {shops &&
                <h1 className="fs-88px text-white">
                    <span>&#3647;</span><CountUp thousandsSeparator="," isCounting end={grandTotalSoldToday(shops)} key={grandTotalSoldToday(shops)} />
                </h1>}
        </>
    );
}

export default TotalToday;