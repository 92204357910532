import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const Download = ({datas, selectedName}) => {

    const dataSet1 = datas

    return ( 
        <div>
        <ExcelFile filename={selectedName} element={<button type="button" className="btn btn-light fs-6">ดาวน์โหลดข้อมูล</button>}>
                <ExcelSheet data={dataSet1} name="Products">
                    <ExcelColumn label="อันดับ" value="id"/>
                    <ExcelColumn label="ข้อมูลสินค้า" value="item_name"/>
                    {/* <ExcelColumn label="จำนวนผู้เยี่ยมชม" value="totalviews"/> */}
                    <ExcelColumn label="SKU" value="sku"/>
                    <ExcelColumn label="ยอดขาย" value="grossamount"/>
                    <ExcelColumn label="จำนวนชิ้นที่ขายได้" value="amount"/>
                    <ExcelColumn label="จำนวนออเดอร์" value="orderamount"/>
                    {/* <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/> */}
                </ExcelSheet>
            </ExcelFile>
        </div>
     );
}
 
export default Download;