import { ReactComponent as IconUp } from '../../icons/icon-trendup.svg'

const upCellRenderer = (props) => {
    const diff = props.value
    return (
        <>
            {/* <span>+</span>{diff}<IconUp className='ms-1' /> */}
            <div className='d-inline rounded-3 bg-success p-2'>
                +{diff}<IconUp className='ms-1' />
            </div>
        </>
    );
}

export default upCellRenderer;