import { useState, useEffect } from 'react'
import TotalDiff from './TotalDiff';
import TotalThisWeek from './TotalThisWeek';
import TotalToday from './TotalToday';
import TotalYesterday from './TotalYesterday';

const TotalSold = () => {

    const [date, setDate] = useState(new Date());
    useEffect(() => {
        var timerID = setInterval(() => {
            tick()
        }, 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    }, []);

    const tick = () => {
        setDate(new Date());
    }

    return (
        <div className="text-center">
            <div className="row">
                <div className="col d-flex">
                    <TotalYesterday />
                </div>
                <div className="col-md-5">
                    <div className="borderRound20 tt-middle p-4 text-white">
                        <div>
                            <span className='fs-5 p-3'>ยอดขายวันนี้</span>
                            <span className='fs-6'>
                                <TotalDiff />
                            </span>
                        </div>

                        <p className="mt-2 p-2 borderRound15 d-inline-block dateTimeClock">
                            {` ${date.toLocaleDateString('th-TH')} ${date.toLocaleTimeString()} (GMT+07) `}
                        </p>
                        <div className="todaySoldText pt-2">
                            <TotalToday />
                        </div>
                    </div>
                </div>
                <div className="col d-flex">
                    <TotalThisWeek />
                </div>
            </div>

        </div >
    );
}

export default TotalSold;