import React from "react";
import { ReactComponent as IconShopee } from "./icon-shopee.svg"
import { ReactComponent as IconLazada } from "./icon-lazada.svg"
import { ReactComponent as IconJd } from "./icon-jd.svg"
import { ReactComponent as IconTopvalue } from "./icon-topvalue.svg"


const iconTypes = {
  Shopee: IconShopee,
  Lazada: IconLazada,
  JD: IconJd,
  POS: IconTopvalue,
  Topvalue: IconTopvalue,
};

const styleIcon = {
  width: '2.5vw',
  paddingRight: '5px'
}


const Icon = ({ name, ...props }) => {
  let Icon = iconTypes[name];
  // console.log(name.toString())

  if (name.toString().toLowerCase() === 'lazada') {
    return <Icon {...props} style={styleIcon} />
  } else {
    return <Icon {...props} style={styleIcon} />
  }


};

export default Icon;
