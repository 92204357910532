import React, { useState } from 'react';
import Apexchart from 'react-apexcharts'
import { MDBSpinner } from 'mdb-react-ui-kit';
import useFetchApexChart from '../../useFetchApexChart ';

const ChartApp = () => {
    const [category] = useState([])

    const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL + '/api/v1/graphs/get' : process.env.REACT_APP_API_URL + '/graphs'
    const { error, isPending, todaysold, yesterdaysold } = useFetchApexChart(url, process.env.NODE_ENV)

    return (
        <div>
            {error && <h2 className="text-center pt-5">{error}</h2>}

            {isPending ?
                <div className='d-flex justify-content-center'>
                    <MDBSpinner className='m-3 mt-5' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </div>
                :
                <Apexchart options={{
                    colors: ['#39ac39', '#d9d9d9'],
                    chart: {
                        id: 'apexchart-example',
                        foreColor: '#fff',
                        height: '100%',
                        toolbar: {
                            show: false
                        }
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        title: {
                            text: 'ยอดขาย(บาท)'
                        },
                        labels: {
                            formatter: function (val, index) {
                                return val.toLocaleString('en-US');
                            }
                        }
                    },
                    xaxis: {
                        categories: category,
                        title: {
                            text: 'ชั่วโมง',
                            offsetX: -525,
                            offsetY: -30,
                        },
                    },
                    title: {
                        text: 'แนวโน้มยอดขาย',
                    },
                    legend: {
                        show: true,
                        floating: true,
                        horizontalAlign: "left",
                        onItemClick: {
                            toggleDataSeries: false
                        },
                        position: "top",
                        offsetY: -33,
                        offsetX: 160
                    },
                }}
                    series={[
                        {
                            name: 'วันนี้',
                            data: todaysold
                        },
                        {
                            name: 'เมื่อวาน',
                            data: yesterdaysold
                        }
                    ]} type="area" />
            }
        </div>
    )
}

export default ChartApp