import { useState,useEffect } from "react"
import ReactPaginate from "react-paginate";
import './Paginate.css'
import NumberFormat from 'react-number-format';

const PaginateData = ({datas}) => {
    const [products, setProducts] = useState([])
    const [pageNumber, setPageNumber] = useState(0)

    const productsPerPage = 10
    const pageVisited = pageNumber * productsPerPage

    const displayProducts = products
        .slice(pageVisited, pageVisited + productsPerPage)
        .map( (product, index) => {
            return (
                // console.log('product',product)
                <tr key={index}>
                    <td>{product.id}</td>
                    <td>{product.item_name}</td>
                    {/* <td>
                        <NumberFormat value={ product.totalviews } displayType={'text'} thousandSeparator={true} />
                    </td> */}
                    <td>
                        <NumberFormat value={ product.sku } displayType={'text'} />
                    </td>
                    <td>
                        <NumberFormat value={ product.grossamount } displayType={'text'} thousandSeparator={true} />
                    </td>
                    <td>
                        <NumberFormat value={ product.amount } displayType={'text'} thousandSeparator={true} />
                    </td>
                    <td>
                        <NumberFormat value={ product.orderamount } displayType={'text'} thousandSeparator={true} />
                    </td>
                </tr>
            )
    })

    const pageCount = Math.ceil(products.length / productsPerPage)

    const changePage = ({selected}) => {
        setPageNumber(selected)
    }

    useEffect(() => {
        setProducts(datas.slice(0,datas.length))
        setPageNumber(0)
    },[datas])

    return ( 
        <div>

            {products.length > 0 && 
                <table className="mt-3 table table-striped table-bordered">
                    <thead className="table-dark">
                        <tr>
                            <td>อันดับ</td>
                            <td>ข้อมูลสินค้า</td>
                            {/* <td>จำนวนผู้เยี่ยมชม</td> */}
                            <td>SKU</td>
                            <td className="tbl-col">ยอดขาย</td>
                            <td className="tbl-col">จำนวนชิ้นที่ขายได้</td>
                            <td className="tbl-col">จำนวนออเดอร์</td>
                        </tr>
                    </thead>
                    <tbody>{displayProducts}</tbody>
                </table>}
            
            {products.length > 0 &&
                <ReactPaginate 
                    forcePage={pageNumber}
                    previousLabel={"ย้อนกลับ"}
                    nextLabel={"ถัดไป"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    />}
        </div>
     );
}
 
export default PaginateData;